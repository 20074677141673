export const sizes = {
  "original": {
    "width": null,
    "height": null
  },
  "large": {
    "width": 1920,
    "height": null
  },
  "medium": {
    "width": 1440,
    "height": null
  },
  "small": {
    "width": 640,
    "height": null
  }
}

export const itemsPerPage = [5, 10, 15, 50, 100]

export const initialCoords = {
  lat: 51,
  lng: 10,
  zoom: 8
}