<template>
  <div class="text-center">
    <v-btn
        text
        @click="onClick"
        v-if="!img"
    >
      <slot>

      </slot>
    </v-btn>
    <v-btn
        :class="size === false ? 'mx-5' : ''"
        :width="size === false ? 50 : 150"
        :height="size === false ? 50 : 150"
        text
        :ripple="false" id="no-background-hover"
        v-else
        elevation="0"
        @click="onClick"
    >
      <slot>

      </slot>
    </v-btn>

    <v-overlay :value="overlay" z-index="99" opacity="0.75" @click.native="onClick">
      <v-container fluid style="padding: 0">
        <v-row style="padding-top: 150px" align="center" justify="center">
          <v-col cols="auto">
            <v-img v-if="src" :src="src"
                   height="500" contain></v-img>
            <v-img v-else :src="imgId ? `data:image/jpeg;base64,${base64Preview}` : getPreview"
                   height="500" contain></v-img>
          </v-col>
        </v-row>
        <h3 class="pt-7" style="position: sticky;">
          {{ filename }}
        </h3>
      </v-container>
    </v-overlay>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";

export default {
  name: 'PreviewOverlay',
  props: ['source', 'filename', 'imgId', 'img', 'src', 'size'],
  data() {
    return {
      overlay: false,
    }
  },
  methods: {
    ...mapActions({
      loadPreview: 'main/loadPreview'
    }),
    async onClick() {
      if (this.imgId && !this.overlay && !this.src) {
        await this.loadPreview(this.imgId)
      }
      this.overlay = !this.overlay
    }
  },
  computed: {
    ...mapGetters({
      getPreview: 'main/getPreview',
      base64Preview: 'main/getBase64Preview'
    })
  },
}
</script>

<style lang="sass">
#no-background-hover::before
  background-color: transparent !important

.v-overlay__content
  min-height: 100%
  min-width: 100%
</style>