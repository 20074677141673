<template>
  <div>
    <v-dialog
        v-model="menu"
        :close-on-content-click="false"
        :nudge-width="700"
        offset-y
        offset-overflow
        tile
        max-width="700"
    >
      <v-card>
        <v-card-title class="justify-center" style="color: #183857;">
          {{
            imgId ? 'Archive this image' : $tc('project.header.archive.items', getSelected.length + getSelectedC2FilesLength)
          }}
        </v-card-title>
        <v-card-actions class="">
          <v-row class="pa-0 ma-0" dense>
            <v-col md="10">
              <v-text-field :label="$t('project.header.archive.label')"
                            :disabled="!getSelected.length && !imgId"
                            outlined
                            readonly
                            ref="link"
                            id="link"
                            key="link"
                            :value="link"
                            @click="downloadZip"
                            :placeholder="$t('project.header.archive.placeholder')">
              </v-text-field>
            </v-col>
            <v-col md="2">
              <v-btn
                  min-width="113"
                  max-width="113"
                  :disabled="!getSelected.length && !imgId"
                  outlined
                  @click="copyLink"
                  height="55"
                  shaped
                  style="overflow: hidden;"
              >
                {{ ($t('copy').length > 11) ? $t('copy').slice(0, 9) + '...' : $t('copy') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
        <v-virtual-scroll v-if="failedFiles.length" :height="400" :items="failedFiles" item-height="50">
          <template v-slot:default="{item}">
            <v-list-item :key="item.fileName" :ref="item.fileName">
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.fileName }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ `${item.size / 1000} kB` }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-content>
                <v-tooltip bottom :disabled="item.reason.length < 36">
                  <template v-slot:activator="{ on, attrs }">
                    <p style="text-overflow: ellipsis; white-space: nowrap; max-width: 300px"
                       v-bind="attrs"
                       v-on="on"
                    >{{ item.reason }}</p>
                  </template>
                  <span>{{ item.reason }}</span>
                </v-tooltip>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon color="error">{{ 'mdi-upload-off' }}</v-icon>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-virtual-scroll>
      </v-card>
    </v-dialog>
    <download-menu :item="item" :open="open" :label="$t('project.header.archive.enterZipName')" v-slot="{on, attrs}">
      <v-btn
          tile
          text
          v-bind="attrs"
          v-on="on"
      >
        <v-icon class="pr-1">{{ 'mdi-archive-arrow-down-outline' }}</v-icon>
        {{ $t('project.header.archive.title') }}
      </v-btn>
    </download-menu>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import SaveFilterPopUp from "@/components/SaveFilterPopUp";
import DownloadMenu from "@/components/DownloadMenu";
import {getSelectedC2FilesLength} from "@/store/main/getters";

export default {
  props: ['projectId', 'imgId', 'item'],
  name: "DownloadZipMenu",
  data() {
    return {
      link: '',
      name: '',
      menu: false,
      slot: false,
      failedFiles: []
    }
  },
  methods: {
    ...mapActions({
      download: 'main/downloadFrom',
      getDownloadLink: 'main/getDownloadLink'
    }),
    downloadZip() {
      this.download(this.link)
    },
    async open(payload) {
      const data = await this.getDownloadLink({
        archiveName: payload.name,
        height: payload.height,
        fileIds: payload.items || this.getSelected.map(item => item.id),
        projectId: this.projectId,
        width: payload.width,
        profile: payload.profile
      })
      this.link = data.deeplink
      this.failedFiles = data.failedMediaFiles

      this.menu = true
      this.name = payload.name
    },

    copyLink() {
      let copyText = document.getElementById("link");
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      document.execCommand("copy");
    }
  },
  computed: {
    ...mapGetters({
      getSelected: 'main/getSelected',
      getSelectedC2FilesLength: 'main/getSelectedC2FilesLength'
    }),
  },
  components: {
    SaveFilterPopUp,
    DownloadMenu
  }
}
</script>