<template>
  <v-menu
      class="download-menu"
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="200"
      offset-y
      offset-overflow
      tile
      max-width="220px"
      :close-on-click="!loading"
  >
    <template v-slot:activator="{ on, attrs }">
      <slot v-if="label" :attrs="attrs"
            :on="on"></slot>
      <v-btn
          v-else
          :tile="!icon"
          :text="!icon"
          :icon="icon"
          v-bind="attrs"
          v-on="on"
      >
        <slot v-if="icon"></slot>
        <v-icon v-else class="pr-1">{{ 'mdi-file-download-outline' }}</v-icon>
        <span v-if="!icon">{{ $t('project.header.download.title') }}</span>
      </v-btn>
    </template>

    <v-card tile v-if="!close">
      <v-container class="pa-2 pb-0">
        <v-row v-if="label" dense>
          <v-col
              class="pb-0"
          >
            <h3 style="color: #183857">{{ label }}</h3>
            <v-text-field :hint="label" persistent-hint v-model="name">

            </v-text-field>
          </v-col>
          <div class="fluid pt-3 pb-2">
            <v-dialog
                v-model="c2Dialog"
                :close-on-content-click="false"
                :nudge-width="700"
                offset-y
                offset-overflow
                tile
                @click:outside="closeDialog"
                max-width="1400"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn tile color="primary"
                       v-bind="attrs"
                       @click="fetchC2entities(entitiesLength)"
                       v-on="on">
                  Knowledge Graph ({{ selectedFilesCounter }})
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  {{ $t('project.header.download.selectFromC2') }}
                </v-card-title>
                <v-card-subtitle>
                  {{ $t('project.header.download.licenseText') }}
                </v-card-subtitle>
                <v-card-text>
                  <v-row>
                    <v-col>
                      <v-text-field :label="$t('project.search')" v-model="search" @input="check"/>
                      <v-list-item-group :value="selectedEntity" @change="setSelectedEntity">
                        <v-list-item v-for="entity in entities" selectable>
                          <v-list-item-title>
                            <p>{{ entityLabel(entity) }}</p>
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            <p>{{ entity.id }}</p>
                          </v-list-item-subtitle>
                        </v-list-item>
                      </v-list-item-group>
                    </v-col>
                    <v-col class="files-container">
                      <h4>
                        {{ entityLabel(selectedEntityComputed) }}
                      </h4>
                      <v-list v-if="files && licensedFiles.length">
                        <v-progress-linear v-if="filesLoading" indeterminate>
                        </v-progress-linear>
                        <v-list-item v-for="file in licensedFiles" class="files-item">
                          <v-list-item-avatar>
                            <v-img :src="webImages + file.thumbUrl">

                            </v-img>
                          </v-list-item-avatar>
                          <p>
                            {{ file.name }}
                          </p>
                          <v-spacer></v-spacer>
                          <v-list-item-action>
                            <v-checkbox
                                @change="remapSelected(file, list[selectedEntity])"
                                :value-comparator="isTrue"
                                :input-value="isFileSelected(list[selectedEntity], file)"
                            />
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                      <div v-else class="message-container justify-center align-center">
                        <h3 class="empty-message">
                          {{ $t('noFiles') }}
                        </h3>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <div class="files-actions">
                      <v-btn tile outlined color="secondary" @click="closeDialog">
                        {{ $t('cancel') }}
                      </v-btn>
                      <v-btn tile outlined color="primary" @click="clearSelection">
                        {{ $t('reset') }}
                      </v-btn>
                      <v-btn tile outlined color="primary" @click="save">
                        {{ $t('save') }} ({{ selectedFilesLength }})
                      </v-btn>
                    </div>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-dialog>
          </div>
        </v-row>
        <v-row dense>
          <v-col
              class="pb-0"
          >
            <h3 style="color: #183857">{{ $t('project.header.download.chooseSize') }}</h3>
            <v-radio-group
                :disabled="loading"
                class="mt-0"
                v-model="size"
                column
            >
              <v-radio
                  :label="$t('project.header.download.original')"
                  value="original"
              >
              </v-radio>

              <v-radio
                  :label="$t('project.header.download.large') + ' (width: '+ sizes.large.width +' px)'"
                  value="large"
              >
              </v-radio>

              <v-radio
                  :label="$t('project.header.download.medium') + ' (width: '+ sizes.medium.width +' px)'"
                  value="medium"
              >
              </v-radio>

              <v-radio
                  :label="$t('project.header.download.small') + ' (width: '+ sizes.small.width +' px)'"
                  value="small"
              >
              </v-radio>

              <v-radio
                  value="other"
                  :label="$t('project.header.download.other') + ' (pixel)'"
              >
              </v-radio>
            </v-radio-group>
            <v-row v-if="size==='other'" dense align="center" justify="center" class="text-center">
              <v-col cols="12" md="5" sm="5" class="pb-0 pt-0">
                <v-text-field :disabled="loading" height="30" outlined
                              :placeholder="$t('project.header.download.width')" v-model="width">
                </v-text-field>
              </v-col>
              <v-col md="2" sm="2" class="pb-8">
                <v-icon>{{ 'mdi-close' }}</v-icon>
              </v-col>
              <v-col cols="12" md="5" sm="5" class="pb-0 pt-0">
                <v-text-field :disabled="loading" outlined :placeholder="$t('project.header.download.height')"
                              v-model="height">
                </v-text-field>
              </v-col>
            </v-row>
            <div style="margin-top: -5px">
              <h3 style="color: #183857">{{ $t('project.header.download.chooseColorProfile') }}</h3>
              <v-radio-group
                  :disabled="loading"
                  class="mt-0"
                  v-model="profile"
              >
                <v-radio
                    value="CMYK"
                    label="CMYK"
                >
                </v-radio>

                <v-radio
                    value="RGB"
                    label="RGB"
                >
                </v-radio>
                <v-radio
                    value="ORIGINAL"
                    :label="$t('original')"
                >
                </v-radio>
              </v-radio-group>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <v-btn
            tile
            :disabled="isSelected || loading"
            color="primary"
            @click="download"
        >
          {{ $t('project.header.download.title') }}
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
      <v-progress-linear
          :active="loading"
          color="primary"
          indeterminate
      ></v-progress-linear>
    </v-card>
  </v-menu>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import {sizes} from '@/../config'

export default {
  name: 'DownloadMenu',
  props: ['icon', 'selected', 'item', 'label', 'open', 'disabled'],
  data: () => ({
    menu: false,
    c2Dialog: false,
    size: 'original',
    entitiesLength: 10,
    selectedProfile: '',
    profile: 'ORIGINAL',
    downloadProfile: '',
    width: null,
    height: null,
    name: '',
    close: false,
    loading: false,
    timer: undefined,
    search: '',
    selectedEntity: '',
    selectedFiles: [],
    list: [],
    files: [],
    filesLoading: false,
    webImages: process.webimagesUrl,
    sizes
  }),
  methods: {
    ...mapActions({
      downloadItems: 'main/downloadItems',
      fetchEntityFiles: 'main/fetchEntityFiles'
    }),
    ...mapMutations({
      setSelectedC2Files: 'main/setSelectedC2Files'
    }),
    save() {
      this.setSelectedC2Files(this.selectedFiles)
      this.closeDialog()
    },
    check() {
      window.clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.requestSearch = this.search
        this.fetchC2entities(10)
      }, 700)
    },
    remapSelected(file, entity) {
      const isEntity = this.selectedFiles.find(obj => obj.entityId === entity.id)
      if (isEntity) {
        const isFileSelected = isEntity.fileNames.find(fn => fn === file.name)
        if (isFileSelected) {
          isEntity.fileNames = isEntity.fileNames.filter(fn => fn != file.name)
          if (!isEntity.fileNames.length) {
            this.selectedFiles = this.selectedFiles.filter(obj => obj.entityId !== entity.id)
          }
        } else {
          isEntity.fileNames.push(file.name)
        }
      } else {
        this.selectedFiles.push({
          entityId: entity.id,
          fileNames: [file.name]
        })
      }
    },
    setSelectedEntity(value) {
      this.selectedEntity = value ?? this.selectedEntity
    },
    async fetchFiles() {
      this.filesLoading = true
      this.files = await this.fetchEntityFiles(this.list[this.selectedEntity]?.id)
      this.filesLoading = false
    },
    async fetchC2entities(size) {
      this.list = await this.$store.dispatch('main/fetchEntities', {
        size,
        search: this.requestSearch,
        id: this.$router.currentRoute.params.id
      })
      this.selectedEntity = 0
      if (this.selectedFilesCounter) {
        this.selectedFiles = this.selectedC2Files
      }
    },
    isTrue(value) {
      return !!value
    },
    closeDialog() {
      this.c2Dialog = false
      this.selectedFiles = []
    },
    clearSelection() {
      this.setSelectedC2Files([])
      this.selectedFiles = []
    },
    async download() {
      this.loading = true
      if (this.profile === 'ORIGINAL') {
        if (this.selected) {
          this.selectedProfile = this.profile
        } else {
          this.downloadProfile = this.item?.profile ?? 'OTHER'
        }
      } else {
        this.downloadProfile = this.profile
      }
      if (this.size !== 'other') {
        let size = sizes[this.size]
        this.width = size.width
        this.height = size.height
      }
      if (this.open) {
        await this.open({
          size: this.size,
          profile: this.downloadProfile,
          width: this.width,
          height: this.height,
          name: this.name
        })
        this.menu = false;
        this.loading = false
      } else {
        if (this.item) {
          await this.downloadItems({
            ...this.item,
            width: this.width,
            height: this.height,
            profile: this.downloadProfile
          })
        } else {
          for (let item of this.getSelected) {
            if (this.selectedProfile === 'ORIGINAL') {
              this.downloadProfile = item.profile ?? ''
            }
            await this.downloadItems({...item, width: this.width, height: this.height, profile: this.downloadProfile})
          }
        }
        this.menu = false;
        this.loading = false
      }
    },
  },
  watch: {
    menu(value) {
      if (value === true && (this.item && this.item.info.type !== "IMAGE")) {
        this.menu = true
        if (this.open) {
          this.open({
            size: 'original',
            profile: 'RGB',
            width: 0,
            height: 0,
            name: this.item.info.fileName,
            items: [this.item.id]
          })
        } else {
          this.downloadItems({...this.item})
        }
        this.close = true
      }
    }
  },
  computed: {
    ...mapGetters({
      getSelected: 'main/getSelected',
      getLocale: 'main/getLocale',
      selectedFilesCounter: 'main/getSelectedC2FilesLength',
      selectedC2Files: 'main/getSelectedC2Files'
    }),
    licensedFiles() {
      return this.files.filter(file => file.media?.metadata?.xmpRights?.UsageTerms[0]?.value)
    },
    selectedFilesLength() {
      let length = 0;
      this.selectedFiles.forEach(entity => {
        length += entity.fileNames.length
      })
      return length
    },
    isFileSelected() {
      return (entity, file) => {
        const isEntity = this.selectedFiles.find(obj => obj.entityId === entity.id)
        if (isEntity) {
          return !!isEntity.fileNames.find(fn => fn === file.name)
        } else {
          return false
        }
      }
    },
    filesComputed() {
      return this.files
    },
    entities() {
      return this.list
    },
    selectedEntityComputed() {
      this.fetchFiles()
      return this.list[this.selectedEntity]
    },
    entityLabel() {
      return entity => {
        return entity?.name?.find(obj => obj.lang === this.getLocale)?.value || entity?.name?.find(obj => obj.lang === 'en-EN')?.value
            || entity?.name?.find(obj => obj.lang === 'de-DE')?.value
      }
    },
    isSelected() {
      if (this.open) {
        if (this.name && this.item) return false
        return !(this.name && (this.getSelected.length || this.selectedFilesCounter))
      } else {
        if (this.item) return false
        return !this.getSelected.length
      }
    }
  },
}
</script>

<style lang="sass">
.v-input__control > .v-messages
  display: none
</style>

<style lang="sass" scoped>
.fluid
  width: 100%
  justify-content: center
  align-items: center
  display: flex

.loading
  width: 100% !important
  height: 100% !important

.files-container
  border: solid 1px black

.files-actions
  display: flex
  justify-content: end
  gap: 10px
  align-content: end
  width: 100%
  margin-top: 10px

.empty-message
  display: block

.message-container
  display: flex
  height: 100%
</style>