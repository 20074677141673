var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"close-on-content-click":false,"nudge-width":700,"offset-y":"","offset-overflow":"","tile":"","max-width":"700"},model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',[_c('v-card-title',{staticClass:"justify-center",staticStyle:{"color":"#183857"}},[_vm._v(" "+_vm._s(_vm.imgId ? 'Archive this image' : _vm.$tc('project.header.archive.items', _vm.getSelected.length + _vm.getSelectedC2FilesLength))+" ")]),_c('v-card-actions',{},[_c('v-row',{staticClass:"pa-0 ma-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"md":"10"}},[_c('v-text-field',{key:"link",ref:"link",attrs:{"label":_vm.$t('project.header.archive.label'),"disabled":!_vm.getSelected.length && !_vm.imgId,"outlined":"","readonly":"","id":"link","value":_vm.link,"placeholder":_vm.$t('project.header.archive.placeholder')},on:{"click":_vm.downloadZip}})],1),_c('v-col',{attrs:{"md":"2"}},[_c('v-btn',{staticStyle:{"overflow":"hidden"},attrs:{"min-width":"113","max-width":"113","disabled":!_vm.getSelected.length && !_vm.imgId,"outlined":"","height":"55","shaped":""},on:{"click":_vm.copyLink}},[_vm._v(" "+_vm._s((_vm.$t('copy').length > 11) ? _vm.$t('copy').slice(0, 9) + '...' : _vm.$t('copy'))+" ")])],1)],1)],1),(_vm.failedFiles.length)?_c('v-virtual-scroll',{attrs:{"height":400,"items":_vm.failedFiles,"item-height":"50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',{key:item.fileName,ref:item.fileName},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.fileName)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(((item.size / 1000) + " kB"))+" ")])],1),_c('v-list-item-content',[_c('v-tooltip',{attrs:{"bottom":"","disabled":item.reason.length < 36},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('p',_vm._g(_vm._b({staticStyle:{"text-overflow":"ellipsis","white-space":"nowrap","max-width":"300px"}},'p',attrs,false),on),[_vm._v(_vm._s(item.reason))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.reason))])])],1),_c('v-list-item-action',[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(_vm._s('mdi-upload-off'))])],1)],1)]}}],null,false,1554157629)}):_vm._e()],1)],1),_c('download-menu',{attrs:{"item":_vm.item,"open":_vm.open,"label":_vm.$t('project.header.archive.enterZipName')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"tile":"","text":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"pr-1"},[_vm._v(_vm._s('mdi-archive-arrow-down-outline'))]),_vm._v(" "+_vm._s(_vm.$t('project.header.archive.title'))+" ")],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }