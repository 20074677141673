<template>
  <div class="pa-0 ma-0">
    <v-dialog
      v-model="dialog"
      width="550"
    >
      <template v-slot:activator="{ on, attrs }">
        <slot v-if="title"   :attrs="attrs"
              :on="on"></slot>
        <v-btn
          v-else
          tile
          depressed
          color="info"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>
            {{ 'mdi-star' }}
          </v-icon>
          {{$t('project.filter.save') }}</v-btn>
      </template>

      <v-card>
        <v-card-title class="headline grey lighten-2">
          {{title || $t('project.filter.chooseName')}}
        </v-card-title>

        <v-card-text>
          <v-text-field :label="$t('name')" v-model="name">

          </v-text-field>
        </v-card-text>


        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              tile
              color="error"
            @click="dialog = false"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
              tile
              color="success"
            :disabled="!validate"
            @click="save"
          >
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <slot v-if="slot"></slot>
  </div>
</template>
<script>

import {mapActions, mapMutations} from "vuex";

export default {
  name: 'UploadPopUp',
  props: ['payload', 'title', 'open', 'disabled'],
  data() {
    return {
      name: '',
      slot: false,
      dialog: false,
    }
  },
  methods: {
    ...mapMutations({
      addFilter: 'main/addFilter'
    }),
    ...mapActions({
      saveFilter: 'main/saveFilter'
    }),
    async save() {

      if(this.title) {
        this.dialog = false
        await this.open(this.name)
      }
      this.dialog = false;
      this.saveFilter({...this.payload, name: this.name})
    }
  },
  computed: {
    validate(){
      return !((this.disabled !== undefined && this.disabled) || !this.name)
    }
  }
}
</script>